.reset-password-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding: 20px;
}

.reset-password-container {
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background: var(--background-color);
  border-radius: 10px;
}

.reset-password-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  background: var(--input-background);
  color: var(--text-color);
}

button {
  width: 100%;
  padding: 12px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: var(--error-color);
  text-align: center;
  margin-top: 10px;
}

.success-message {
  color: var(--success-color);
  text-align: center;
  margin-top: 10px;
}
