.calculadora-faqs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.calculadora-faq-item {
  padding: 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.calculadora-faq-item h2 {
  color: var(--font-color);
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.calculadora-faq-item p,
.calculadora-faq-item ul,
.calculadora-faq-item ol {
  color: var(--font-color);
  margin-bottom: 15px;
}

.calculadora-faq-item ul,
.calculadora-faq-item ol {
  padding-left: 20px;
}

.calculadora-faq-item li {
  margin-bottom: 10px;
}

.einstein-quote {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 60%;
  background-color: var(--secondary-color);
}

.quote-text {
  flex: 1;
  font-family: "Nohemi", sans-serif;
  color: var(--font-color);
}

.quote-image img {
  max-width: 100px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-item h3 {
  color: var(--font-color);
  margin-bottom: 5px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .calculadora-faqs-grid {
    grid-template-columns: 1fr;
  }
  .einstein-quote {
    width: 100%;
  }
}
