@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colores principales */
  --primary-color: theme("colors.emerald.500");
  --secondary-color: theme("colors.slate.900");
  --accent-color: theme("colors.cyan.400");

  /* Colores de texto */
  --text-primary: theme("colors.gray.100");
  --text-secondary: theme("colors.gray.400");
  --font-color: theme("colors.gray.100");

  /* Colores de fondo */
  --bg-primary: theme("colors.gray.900");
  --bg-secondary: theme("colors.gray.800");
  --bg-hover: theme("colors.gray.600");
  --bg-card: theme("colors.gray.800");

  /* Colores de borde */
  --border-color: theme("colors.gray.700");

  /* Colores de notificación */
  --success-bg: theme("colors.green.500/30");
  --success-border: theme("colors.green.500/20");
  --success-text: theme("colors.green.400");

  --error-bg: theme("colors.red.600/30");
  --error-border: theme("colors.red.500/20");
  --error-text: theme("colors.red.400");

  /* Efectos */
  --backdrop-blur: blur(8px);
  --card-shadow: theme("boxShadow.lg");

  /* Gradientes */
  --gradient-primary: linear-gradient(
    to right,
    theme("colors.emerald.500"),
    theme("colors.cyan.400")
  );
  --gradient-card: linear-gradient(
    to right,
    theme("colors.orange.500"),
    theme("colors.amber.400")
  );
}

/* Estilos base */
@layer base {
  body {
    @apply bg-gradient-to-b from-gray-900 to-black text-gray-100 min-h-screen font-sans;
    font-family: "Poppins", system-ui, -apple-system, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply bg-gradient-to-r from-emerald-400 to-cyan-400 bg-clip-text text-transparent font-bold;
    font-family: "Poppins", system-ui, -apple-system, sans-serif;
  }
}

/* Componentes comunes */
@layer components {
  .auth-container {
    @apply max-w-md w-full space-y-8 bg-white/10 backdrop-blur-lg p-8 rounded-2xl border border-gray-700/50;
  }

  .auth-input {
    @apply appearance-none relative block w-full px-4 py-3 border border-gray-700 bg-gray-800/50 placeholder-gray-400 text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all duration-300;
  }

  .auth-button {
    @apply w-full max-w-[300px] flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white bg-gradient-to-r from-emerald-500 to-cyan-500 hover:from-emerald-600 hover:to-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .auth-link {
    @apply font-medium text-emerald-400 hover:text-emerald-300 transition-colors duration-300;
  }

  .message-box {
    @apply mt-4 p-4 rounded-xl text-center;
  }

  .success-message {
    @apply bg-emerald-500/20 text-emerald-200;
  }

  .error-message {
    @apply bg-red-500/20 text-red-200;
  }
}

/* Utilidades personalizadas */
@layer utilities {
  .text-gradient {
    @apply bg-gradient-to-r from-emerald-100 to-cyan-400 bg-clip-text text-transparent;
  }

  .bg-gradient-primary {
    @apply bg-gradient-to-r from-emerald-500 to-cyan-500;
  }

  .bg-gradient-hover {
    @apply hover:from-emerald-600 hover:to-cyan-600;
  }
}

/* Animaciones */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Spinner para el chat */
.chat-spinner {
  @apply w-8 h-8 border-4 border-emerald-400/30 border-t-emerald-400 rounded-full animate-spin mx-auto;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out;
}

.animate-slide-down {
  animation: slide-down 0.3s ease-out;
}

/* Actualizar las clases que usan estos colores */
.bg-gradient-primary {
  background: var(--gradient-primary);
}

.text-gradient {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Notificaciones */
.notification-success {
  background: var(--success-bg);
  border-color: var(--success-border);
  color: var(--success-text);
  backdrop-filter: var(--backdrop-blur);
}

.notification-error {
  background: var(--error-bg);
  border-color: var(--error-border);
  color: var(--error-text);
  backdrop-filter: var(--backdrop-blur);
}
