.app-description {
  text-align: center;
}

.variablesInteres {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 1rem;
  border-radius: 15px;
}

.variablesInteres .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.variablesInteres label {
  margin-bottom: 5px;
  white-space: nowrap; /* Prevent label from wrapping */
}

.variablesInteres input,
.input-currency-wrapper input,
.input-no-currency-wrapper input {
  width: 100%;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  background-color: #ffffff1e;
  color: var(--font-color);
  font-size: 16px;
}

.variablesInteres h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 5px;
}

.resultadoInteres {
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  background-color: #ffffff09;
  border: 0.75px solid var(--border-color);
}

.calculadoraContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
}

.calculadoraValores {
  width: 25%;
}

.calculadoraGrafico {
  width: 50%;
  padding: 20px;
  display: flex;
  border-radius: 15px;
}

#grafico {
  width: 100%;
  height: auto;
}

.calculadoraPie {
  background-color: var(--secondary-color);
  padding: 10px;
  width: 25%;
  border-radius: 15px;
}

.calculadoraGrafico {
  background-color: var(--secondary-color);
  padding: 20px;
}

input {
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

.calculadoraInfo {
  background-color: var(--secondary-color);
  padding: 50px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.resultado-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.resultado-value {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--primary-color);
}

.resultado-value-ganado {
  font-size: 1.1em;
  font-weight: bold;
  color: #6dd08c;
}

.resultado-value-anos {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--font-color);
}

.einstein-quote {
  display: flex;
  background-color: var(--background-color);
  border-left: 5px solid #333;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 15px 15px 0px;
  width: 30%;
}

.quote-text {
  flex: 1;
  padding-right: 20px;
}

.quote-text p {
  font-style: italic;
  font-weight: 500;
  font-size: 1em;
  color: var(--font-color);
}

.quote-text span {
  font-size: 1em;
  color: var(--font-color);
}

.quote-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-image img {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

@media (max-width: 768px) {
  .einstein-quote {
    flex-direction: column;
  }

  .quote-text {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .calculadoraContainer {
    flex-direction: column; /* Cambiar a disposición vertical */
  }
  .calculadoraValores {
    width: 100%;
  }
  .calculadoraGrafico {
    width: 100%;
  }
  #grafico {
    width: 100%;
    height: auto;
    max-height: 300px;
  }
  .calculadoraPie {
    width: 100%;
    height: auto;
  }

  .calculadoraInfo {
    padding: 20px;
  }

  .calculadoraInfo p {
    text-align: justify;
  }

  .variablesInteres .input-group {
    flex-direction: column;
    align-items: stretch;
  }

  .variablesInteres label {
    margin-bottom: 5px;
    flex: 0 0 100%;
  }

  .variablesInteres input {
    width: 100%;
  }

  .einstein-quote {
    display: flex;
    flex-direction: row;
    background-color: var(--background-color);
    border-left: 5px solid #333;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 15px 15px 0px;
    width: 100%;
  }

  .einstein-quote img {
    width: 90%;
  }

  .quote-text {
    font-size: 0.8em;
  }

  .resultado-item {
    padding: 0;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-currency-wrapper {
  position: relative;
  width: 70%;
}

.input-currency-wrapper input {
  width: 100%;
  padding-right: 20px;
}

.currency-symbol {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input-buttons {
  width: 26%; /* 13% + 13% */
  margin-left: 4%; /* Remaining 4% for spacing */
  gap: 4px; /* Add gap between buttons */
}

.input-buttons button {
  width: 50%;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  height: 100%;
  transition: background-color 0.3s ease;
  color: var(--font-color);
}

/* For devices that support hover */
@media (hover: hover) {
  .input-buttons button:hover {
    background-color: var(--primary-color);
    color: var(--font-color);
  }
}

/* For touch devices */
.input-buttons button:active {
  background-color: var(--primary-color);
  color: var(--font-color);
}

/* Remove the hover effect for mobile devices */
@media (max-width: 768px) {
  .input-buttons button:hover {
    background-color: var(--secondary-color);
    color: var(--font-color);
  }

  .input-buttons button:active {
    background-color: var(--primary-color);
    color: var(--font-color);
  }
}

.input-buttons button:first-child {
  border-radius: 3px 0 0 3px;
}

.input-buttons button:last-child {
  border-radius: 0 3px 3px 0;
}

@media (max-width: 768px) {
  .input-wrapper {
    flex-wrap: nowrap;
  }

  .input-currency-wrapper {
    width: 70%;
    margin-bottom: 0;
  }

  .input-buttons {
    width: 26%;
    margin-left: 4%;
    gap: 2px; /* Slightly smaller gap on mobile */
  }

  .input-buttons button {
    padding: 5px 0;
    font-size: 14px;
  }

  /* Other mobile adjustments... */
}

/* Add this to your existing CSS */

.input-wrapper-no-currency {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-no-currency-wrapper {
  position: relative;
  width: 70%;
}

.input-no-currency-wrapper input {
  width: 100%;
  padding-right: 5px;
}

/* The rest of the styles for .input-buttons and its children remain the same */

/* Hide default spinner buttons for Webkit browsers (Chrome, Safari, newer versions of Opera) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide default spinner buttons for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide default spinner buttons for Edge */
input[type="number"]::-ms-clear,
input[type="number"]::-ms-reveal {
  display: none;
}

/* You may want to adjust the placeholder color as well */
.variablesInteres input::placeholder,
.input-currency-wrapper input::placeholder,
.input-no-currency-wrapper input::placeholder {
  color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Semi-transparent white for placeholder text */
}

.variablesInteres input,
.input-currency-wrapper input,
.input-no-currency-wrapper input {
  caret-color: white;
}
