.App-header h1 {
  text-align: center;
}

.app-title {
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.logo {
  width: 100px;
  height: auto;
}
