.legal-notice-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: var(--text-color);
}

.legal-section {
  margin-bottom: 40px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  border: 0.75px solid rgba(255, 255, 255, 0.125);
}

.legal-section h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.legal-section h3 {
  color: var(--accent-color);
  margin: 20px 0 10px;
}

.tech-list ul {
  list-style-type: none;
  padding-left: 20px;
}

.tech-list li {
  margin: 8px 0;
  position: relative;
}

.tech-list li::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: -15px;
}

@media (max-width: 768px) {
  .legal-notice-container {
    padding: 10px;
  }

  .legal-section {
    padding: 15px;
  }
}
